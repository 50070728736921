<template>
  <v-container
    id="video-container"
    class="pa-0"
  >
    <v-row class="ma-0 row-height">
      <v-col class="pa-0 row-height">
        <VideoCanvasControls
          ref="fullscreenFrame"
          :current-time="currentTime"
          :duration="duration"
          :show-advanced-controls="true"
          @seeked="seekVideos"
          @played="playVideos"
          @paused="pauseVideos"
          @previous="prevVideo"
          @next="nextVideo(true)"
        >
          <canvas
            ref="fullscreenCanvas"
            class="player"
          />
        </VideoCanvasControls>
      </v-col>
    </v-row>
    <!--<v-row>
      <v-col>
        <span
          class="orange--text"
        >Canvas Fps: {{ canvasFps }}</span>
      </v-col>
    </v-row>
    <v-row v-for="(row,vsi) in videoPlayerStats"
           :key="vsi+1000"
    >
      <v-col>
        <span
          class="orange--text"
        >{{ getVideoPlayersStats(vsi) }}</span>
      </v-col>
    </v-row>-->
    <v-row id="video-inner-container"
           class="hiddenPlayer"
           align="center"
    >
      <v-col class="pa-4"
             cols="5"
      >
        <video
          ref="videoPlayer1"
          width="100%"
          height="100%"
          class="player"
          preload="auto"
          crossorigin="use-credentials"
        />
        <!--<v-row>
          <v-col
            class="text-left"
          >
            <span class="white--text">
              {{ $t('videoStudio.capture') }} {{ captureId1 }}
            </span>
          </v-col>
          <v-col class="text-right">
            <span v-if="currentTime"
                  class="white--text"
            >{{ currentTime | moment("00:ss") }}</span>
            <span v-else
                  class="white--text"
            >00:00</span>
          </v-col>
        </v-row>-->
      </v-col>
      <v-col
        class="pa-4"
        cols="5"
      >
        <video
          ref="videoPlayer2"
          width="100%"
          height="100%"
          class="player"
          preload="auto"
          crossorigin="use-credentials"
        />
        <!--<v-row>
          <v-col
            class="text-left"
          >
            <span class="white--text">
              {{ $t('videoStudio.capture') }} {{ captureId2 }}
            </span>
          </v-col>
          <v-col class="text-right">
            <span v-if="currentTime"
                  class="white--text"
            >{{ currentTime | moment("00:ss") }}</span>
            <span v-else
                  class="white--text"
            >00:00</span>
          </v-col>
      </v-col>-->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import VideoCanvasControls from './VideoCanvasControls';
import config from '../../js/config';
import capturesService from '../../js/services/capturesService';

export default {
  name:       'VideoPlaylistPlayer',
  components: {
    VideoCanvasControls,
  },
  props: {
    playlists: {
      type:    Array,
      default: () => [],
    },
    showDate: {
      type:    Boolean,
      default: false,
    },
    showTimestamp: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      duration:          0,
      durations:         [ 0, 0 ],
      currentTime:       0,
      progress:          0,
      playing:           false,
      videoPlayers:      [],
      playlistIdx:       0,
      videoHeight:       720,
      videoWidth:        720,
      rowXOffset:        0,
      rowYOffset:        0,
      videosPlaying:     0,
      playlist1:         [],
      playlist2:         [],
      canvasRenderCount: 0,
      canvasRenderStart: 0,
      canvasFps:         0,
      videoPlayerStats:  [],
    };
  },
  watch: {
    playlists: {
      deep: true,
      handler(newVal) {
        if (newVal.length !== 2) {
          return;
        }

        if (this.playing) {
          this.pauseVideos();
        }

        [ this.playlist1, this.playlist2 ] = newVal;

        this.loadVideos();
      },
    },
  },
  created() {
    window.addEventListener('resize', this.windowResized);
  },
  destroyed() {
    // clearInterval(this.timer);
    window.removeEventListener('resize', this.windowResized);
  },
  mounted() {
    this.initVideoPlayers();
    this.loadVideos();
    // this.timer = setInterval(this.handleFrame, 33);
    this.handleFrame();
  },
  methods: {
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback);
      });
    },
    windowResized() {
      this.updateDimensions();

      // this.handleFrame();
    },
    updateDimensions() {
      if (!this.$refs.fullscreenCanvas) {
        return;
      }
      // console.log(`client (WxH) (${this.$refs.fullscreenCanvas.clientWidth}x${this.$refs.fullscreenCanvas.clientHeight}) (WxH) (${this.$refs.fullscreenCanvas.width}x${this.$refs.fullscreenCanvas.height})`);

      if (this.$refs.fullscreenCanvas.clientHeight !== this.$refs.fullscreenCanvas.height) {
        this.$refs.fullscreenCanvas.height = this.$refs.fullscreenCanvas.clientHeight;
      }

      if (this.$refs.fullscreenCanvas.clientWidth !== this.$refs.fullscreenCanvas.width) {
        this.$refs.fullscreenCanvas.width = this.$refs.fullscreenCanvas.clientWidth;
      }
    },
    updateTime(el) {
      this.currentTime = el.target.currentTime;
    },
    updateDurations() {
      let idx = 0;

      if (this.durations[1] > this.durations[0]) {
        idx = 1;
      }

      const maxDuration = this.durations[idx];

      if (maxDuration) {
        const maxDurationVideo = this.videoPlayers[idx];

        this.duration = maxDuration;
        this.videoPlayers.forEach(player => {
          player.removeEventListener('timeupdate', this.updateTime);
        });
        maxDurationVideo.addEventListener('timeupdate', this.updateTime, false);
      }
    },
    loadVideos() {
      this.playlistIdx = 0;

      let fr1 = 0;
      let fr2 = 0;

      if (this.playlist1.length > 0) {
        this.$refs.videoPlayer1.src = this.playlist1[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer1.createdDateTime = this.playlist1[this.playlistIdx].date_created;
        fr1 = capturesService.getFrameRate(this.playlist1[this.playlistIdx].scan_mode);
      } else {
        this.durations[0] = 0;
        this.$refs.videoPlayer1.src = '';
        this.$refs.videoPlayer1.createdDateTime = null;
      }

      if (this.playlist2.length > 0 && this.playlistIdx < this.playlist2.length) {
        this.$refs.videoPlayer2.src = this.playlist2[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer2.createdDateTime = this.playlist2[this.playlistIdx].date_created;

        fr2 = capturesService.getFrameRate(this.playlist2[this.playlistIdx].scan_mode);
      } else {
        this.durations[1] = 0;
        this.$refs.videoPlayer2.src = '';
        this.$refs.videoPlayer2.createdDateTime = null;
      }

      this.$refs.videoPlayer1.playbackRate = 1.0;
      this.$refs.videoPlayer2.playbackRate = 1.0;

      // Only set rate if there are 2 videos
      if (fr1 !== 0 && fr2 !== 0) {
        if (fr1 <= fr2) {
          this.$refs.videoPlayer2.playbackRate = fr1 / fr2;
        } else {
          this.$refs.videoPlayer1.playbackRate = fr2 / fr1;
        }
      }
    },
    initVideoPlayers() {
      this.videoPlayers = [];
      this.videoPlayerStats = [];
      this.duration = 0;
      this.currentTime = 0;
      this.durations = [ 0, 0 ];

      this.videoPlayers.push(this.$refs.videoPlayer1);
      this.videoPlayers.push(this.$refs.videoPlayer2);
      this.videoPlayerStats.push({
        parsedFrames:    0,
        decodedFrames:   0,
        presentedFrames: 0,
        paintedFrames:   0,
        frameDelay:      0,
        droppedFrames:   0,
        totalFrames:     0,
      });
      this.videoPlayerStats.push({
        parsedFrames:    0,
        decodedFrames:   0,
        presentedFrames: 0,
        paintedFrames:   0,
        frameDelay:      0,
        droppedFrames:   0,
        totalFrames:     0,
      });

      const context = this;

      this.videoPlayers.forEach((videoPlayer, index) => {
        /*
        Do we need to add this back to schronize the loading?
        videoPlayer.addEventListener('waiting', () => {
          context.waitingPause(index);
        }, false);*/

        videoPlayer.addEventListener('canplay', () => {
          this.continuePlay();
        }, false);

        videoPlayer.addEventListener('loadedmetadata', () => {
          context.durations[index] = videoPlayer.duration;
          context.updateDurations();
        }, false);

        videoPlayer.addEventListener('ended', () => {
          this.videosPlaying--;
          if (this.videosPlaying === 0) {
            context.nextVideo(false, context);
          }
        }, false);
      });

      this.doubleRaf(() => {
        this.windowResized();
      });
    },

    updateVideoDimension() {
      if (!this.$refs.fullscreenCanvas) {
        return;
      }

      const viewWidth = this.$refs.fullscreenCanvas.width;
      const viewHeight = this.$refs.fullscreenCanvas.height;

      const viewAr = viewWidth / viewHeight;
      const dim = Math.min(Math.round(viewWidth / 2), viewHeight);

      this.videoHeight = dim;
      this.videoWidth = dim;

      if (viewAr > 2) {
        // pillarbox
        this.rowXOffset = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
        this.rowYOffset = 0;
      } else {
        // letterbox
        this.rowXOffset = 0;
        this.rowYOffset = Math.round(Math.abs(dim - viewHeight) / 2);
      }
    },

    getCaptureCreateDate(dateCreated, currentPosition = 0) {
      return this.$moment(dateCreated).add(currentPosition, 'ms').format(config.dateFormat);
    },
    getCaptureCreateTimestamp(dateCreated, currentPosition = 0) {
      return this.$moment(dateCreated).add(currentPosition, 'ms').format(config.timeFormat);
    },

    handleFrame() {
      if (!this.$refs.fullscreenCanvas) {
        requestAnimationFrame(() => { this.handleFrame(); });

        return;
      }

      const ctx = this.$refs.fullscreenCanvas.getContext('2d');

      if (!ctx) {
        requestAnimationFrame(() => { this.handleFrame(); });

        return;
      }

      if (this.canvasRenderStart === 0) {
        this.canvasRenderStart = performance.now();
      }

      this.updateDimensions();

      this.updateVideoDimension();

      ctx.clearRect(0, 0, this.$refs.fullscreenCanvas.width, this.$refs.fullscreenCanvas.height);

      try {
        ctx.save();

        ctx.drawImage(this.videoPlayers[0], this.rowXOffset, this.rowYOffset,
          this.videoWidth, this.videoHeight);

        ctx.drawImage(this.videoPlayers[1], this.rowXOffset + this.videoWidth, this.rowYOffset,
          this.videoWidth, this.videoHeight);

        if (this.showTimestamp || this.showDate) {
          ctx.font = '14px Roboto';

          // Left Side
          if (this.videoPlayers[0].createdDateTime) {
            let overlayText = '';

            if (this.showDate) {
              overlayText += this.getCaptureCreateDate(
                this.videoPlayers[0].createdDateTime, this.currentTime * 1000,
              );
              overlayText += ' ';
            }

            if (this.showTimestamp) {
              overlayText += this.getCaptureCreateTimestamp(
                this.videoPlayers[0].createdDateTime, this.currentTime * 1000,
              );
            }

            const textWidth = ctx.measureText(overlayText).width;

            ctx.beginPath();

            const textYOffset = this.rowYOffset +  this.videoHeight - 20;

            ctx.rect(this.rowXOffset, textYOffset, textWidth + 6, 20);
            ctx.fillStyle = 'black';
            ctx.fill();

            ctx.fillStyle = 'WhiteSmoke';
            ctx.fillText(overlayText, this.rowXOffset + 3, this.rowYOffset +  this.videoHeight - 4);
          }

          // Right Side
          if (this.videoPlayers[1].createdDateTime) {
            let overlayText = '';

            if (this.showDate) {
              overlayText += this.getCaptureCreateDate(
                this.videoPlayers[1].createdDateTime, this.currentTime * 1000,
              );
              overlayText += ' ';
            }

            if (this.showTimestamp) {
              overlayText += this.getCaptureCreateTimestamp(
                this.videoPlayers[1].createdDateTime, this.currentTime * 1000,
              );
            }

            const textWidth = ctx.measureText(overlayText).width;

            ctx.beginPath();

            const textYOffset = this.rowYOffset +  this.videoHeight - 20;

            ctx.rect(this.rowXOffset + this.videoWidth, textYOffset, textWidth + 6, 20);
            ctx.fillStyle = 'black';
            ctx.fill();

            ctx.fillStyle = 'WhiteSmoke';
            ctx.fillText(overlayText,
              this.rowXOffset + this.videoWidth + 3, this.rowYOffset +  this.videoHeight - 4);
          }
        }

        ctx.restore();
      } catch (ex) {
        requestAnimationFrame(() => { this.handleFrame(); });

        // console.error(`failed to drawImage`);
        // console.error(`failed to drawImage ${ex}`);
        return;
      }

      this.canvasRenderCount += 1;

      if ((this.canvasRenderCount % 5) === 0) {
        this.canvasFps = this.canvasRenderCount
              / ((performance.now() - this.canvasRenderStart) / 1000);
        this.updateStats();
      }
      requestAnimationFrame(() => { this.handleFrame(); });
    },

    updateStats() {
      this.videoPlayers.forEach((player, index) => {
        const qual = player.getVideoPlaybackQuality();

        if (player.mozParsedFrames !== undefined) {
          this.videoPlayerStats[index].parsedFrames =    player.mozParsedFrames;
          this.videoPlayerStats[index].decodedFrames =   player.mozDecodedFrames;
          this.videoPlayerStats[index].presentedFrames = player.mozPresentedFrames;
          this.videoPlayerStats[index].paintedFrames =   player.mozPaintedFrames;
          this.videoPlayerStats[index].frameDelay =      player.mozFrameDelay;
        }
        this.videoPlayerStats[index].droppedFrames =   qual.droppedVideoFrames;
        this.videoPlayerStats[index].totalFrames =     qual.totalVideoFrames;
      });
    },

    getVideoPlayersStats(index) {
      if (this.videoPlayers.length <= 0) {
        return '';
      }

      if (this.videoPlayers[index].mozParsedFrames !== undefined) {
        let txt = `Decode Drop: ${this.videoPlayerStats[index].parsedFrames - this.videoPlayerStats[index].decodedFrames},`;

        txt += ` Present Drop: ${this.videoPlayerStats[index].decodedFrames - this.videoPlayerStats[index].presentedFrames},`;
        txt += ` Paint Drop: ${this.videoPlayerStats[index].presentedFrames - this.videoPlayerStats[index].paintedFrames},`;
        txt += ` Frame Delay: ${this.videoPlayerStats[index].frameDelay},`;
        txt += ` Dropped ${this.videoPlayerStats[index].droppedFrames} frames of ${this.videoPlayerStats[index].totalFrames}`;

        return txt;
      }

      const txt = ` Dropped ${this.videoPlayerStats[index].droppedFrames} frames of ${this.videoPlayerStats[index].totalFrames}`;

      return txt;
    },

    getPlaylist(index) {
      if (index === 0) {
        return this.playlist1;
      }

      return this.playlist2;
    },

    /**
     * Play all videos.
     */
    playVideos() {
      this.canvasRenderStart = 0;
      this.canvasRenderCount = 0;
      this.playing = true;
      this.videoPlayers.forEach(videoPlayer => {
        // if we set the src to empty string, according to the spec it will load the page url
        if (videoPlayer.src !== window.location.href) {
          this.videosPlaying++;
          videoPlayer.play();
        }
      });
    },

    seekVideos(newTime) {
      let isSeeking = false;

      this.videoPlayers.forEach(videoPlayer => {
        if (videoPlayer.seeking) {
          isSeeking = true;
        }
      });
      if (isSeeking) {
        return;
      }

      this.videoPlayers.forEach(videoPlayer => {
        videoPlayer.currentTime = newTime;
      });
    },

    /**
     * Pause all videos.
     */
    pauseVideos() {
      this.playing = false;
      this.videoPlayers.forEach(videoPlayer => {
        // if we set the src to empty string, according to the spec it will load the page url
        if (videoPlayer.src !== window.location.href) {
          this.videosPlaying--;
          videoPlayer.pause();
        }
      });
    },

    /**
     * Pause all videos when one in waiting phase.
     *
     * @params {number} - Video index.
     */
    waitingPause(indexWaiting) {
      this.videoPlayers.forEach((videoPlayer, index) => {
        const playlist = this.getPlaylist(index);

        // if  this is not our player, we will pause.
        // but only if that player has videos.
        if (indexWaiting !== index && playlist.length > 0) {
          videoPlayer.pause();
        }
      });
    },

    /**
     * Continue play videos after waiting pause.
     *
     * @params {number} - Video index.
     */
    continuePlay() {
      if (this.playing) {
        this.canvasRenderStart = 0;
        this.canvasRenderCount = 0;
        this.videoPlayers.forEach(videoPlayer => {
          // if we set the src to empty string, according to the spec it will load the page url
          if (videoPlayer.src !== window.location.href) {
            videoPlayer.play();
          }
        });
      }
    },

    nextVideo(reset) {
      if (reset) {
        this.videosPlaying = 0;
      }

      let fr1 = 0;
      let fr2 = 0;

      this.playlistIdx++;

      if (this.playlistIdx >= Math.max(this.playlist1.length, this.playlist2.length)) {
        this.playlistIdx = 0;
      }

      if (this.playlistIdx < this.playlist1.length) {
        if (this.playing) {
          this.videosPlaying++;
        }
        this.$refs.videoPlayer1.src = this.playlist1[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer1.createdDateTime = this.playlist1[this.playlistIdx].date_created;
        fr1 = capturesService.getFrameRate(this.playlist1[this.playlistIdx].scan_mode);
      } else {
        this.durations[0] = 0;
        this.$refs.videoPlayer1.src = '';
        this.$refs.videoPlayer1.createdDateTime = null;
      }

      if (this.playlistIdx < this.playlist2.length) {
        if (this.playing) {
          this.videosPlaying++;
        }
        this.$refs.videoPlayer2.src = this.playlist2[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer2.createdDateTime = this.playlist2[this.playlistIdx].date_created;

        fr2 = capturesService.getFrameRate(this.playlist2[this.playlistIdx].scan_mode);
      } else {
        this.durations[1] = 0;
        this.$refs.videoPlayer2.src = '';
        this.$refs.videoPlayer2.createdDateTime = null;
      }

      this.$refs.videoPlayer1.playbackRate = 1.0;
      this.$refs.videoPlayer2.playbackRate = 1.0;

      // Only set rate if there are 2 videos
      if (fr1 !== 0 && fr2 !== 0) {
        if (fr1 <= fr2) {
          this.$refs.videoPlayer2.playbackRate = fr1 / fr2;
        } else {
          this.$refs.videoPlayer1.playbackRate = fr2 / fr1;
        }
      }
    },

    prevVideo() {
      this.videosPlaying = 0;

      let fr1 = 0;
      let fr2 = 0;

      this.playlistIdx--;

      if (this.playlistIdx < 0) {
        this.playlistIdx = Math.max(this.playlist1.length - 1, this.playlist2.length - 1);
      }

      if (this.playlistIdx < this.playlist1.length) {
        if (this.playing) {
          this.videosPlaying++;
        }
        this.$refs.videoPlayer1.src = this.playlist1[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer1.createdDateTime = this.playlist1[this.playlistIdx].date_created;
        fr1 = capturesService.getFrameRate(this.playlist1[this.playlistIdx].scan_mode);
      } else {
        this.durations[0] = 0;
        this.$refs.videoPlayer1.src = '';
        this.$refs.videoPlayer1.createdDateTime = null;
      }

      if (this.playlistIdx < this.playlist2.length) {
        if (this.playing) {
          this.videosPlaying++;
        }
        this.$refs.videoPlayer2.src = this.playlist2[this.playlistIdx].video;
        // Set this for the date timestamp rendering
        this.$refs.videoPlayer2.createdDateTime = this.playlist2[this.playlistIdx].date_created;

        fr2 = capturesService.getFrameRate(this.playlist2[this.playlistIdx].scan_mode);
      } else {
        this.durations[1] = 0;
        this.$refs.videoPlayer2.src = '';
        this.$refs.videoPlayer2.createdDateTime = null;
      }

      this.$refs.videoPlayer1.playbackRate = 1.0;
      this.$refs.videoPlayer2.playbackRate = 1.0;

      // Only set rate if there are 2 videos
      if (fr1 !== 0 && fr2 !== 0) {
        if (fr1 <= fr2) {
          this.$refs.videoPlayer2.playbackRate = fr1 / fr2;
        } else {
          this.$refs.videoPlayer1.playbackRate = fr2 / fr1;
        }
      }
    },

  },
};

</script>

<style scoped lang="scss">
@import "../../css/variables";

#video-container {
  height: 70vh;
  background-color: $grey-darken-2;
  #player-controls-container {
    background-color: $grey-lighten-1;
  }
}

.row-height {
  height:100%;
}

.player {
  height: 100%;
  width: 100%;
  position: relative;
}

.hiddenPlayer {
  display: none;
}

</style>

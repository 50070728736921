<template>
  <v-app>
    <SidePanel v-if="withLayout"
               ref="sidePanel"
    />
    <HeaderToolBar v-if="withLayout"
                   @toggle-navbar="toggleNavbar"
    />
    <v-main v-if="withLayout">
      <router-view />
    </v-main>
    <v-main v-else>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import HeaderToolBar from './components/HeaderToolBar';
import SidePanel from './components/SidePanel';

export default {
  name:       'App',
  components: {
    HeaderToolBar,
    SidePanel,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    withLayout() {
      return !this.$route.meta.withOutLayout;
    },
    imageStudio() {
      return true;
    },
  },
  mounted() {
    // console.log(this.$vuetify.breakpoint);
  },
  methods: {
    toggleNavbar() {
      if (this.$refs.sidePanel && this.withLayout) {
        this.$refs.sidePanel.toggleNavbar();
      }
    },

  },
};
</script>
<style scoped>

.v-main {
  background-color: var(--v-pageBackground-base);
}

html {
  overflow-x: auto;
}
</style>

<template>
  <v-container
    id="video-container"
    class="pa-0"
  >
    <v-row class="ma-0 row-height">
      <v-col class="pa-0 row-height">
        <VideoCanvasControls
          ref="fullscreenFrame"
          :current-time="currentTime"
          :duration="duration"
          @seeked="seekVideos"
          @played="playVideos"
          @paused="pauseVideos"
        >
          <canvas
            ref="fullscreenCanvas"
            class="player"
          />
        </VideoCanvasControls>
      </v-col>
    </v-row>
    <!--<v-row>
      <v-col>
        <span
          class="orange--text"
        >Canvas Fps: {{ canvasFps }}</span>
      </v-col>
    </v-row>
    <v-row v-for="(row,vsi) in videoPlayerStats"
           :key="vsi+1000"
    >
      <v-col>
        <span
          class="orange--text"
        >{{ getVideoPlayersStats(vsi) }}</span>
      </v-col>
    </v-row>-->
    <v-row
      v-for="(row,ri) in rows"
      :key="ri"
      align="center"
      class="hiddenPlayer"
    >
      <v-col v-for="(video,ci) in row"
             :key="ci"
      >
        <video
          v-if="video !== null"
          :ref="getIndex(ri, ci)"
          loop
          preload="auto"
          disablePictureInPicture
          crossorigin="use-credentials"
          class="hiddenPlayer"
        />
        <!--<v-row v-if="video !== null">
          <v-col
            class="text-left"
          >
            <span class="white--text">
              {{ $t('videoStudio.capture') }} {{ video.captureId }}
            </span>
          </v-col>
          <v-col class="text-right">
            <span v-if="currentTime"
                  class="white--text"
            >{{ currentTime | moment("00:ss") }}</span>
            <span v-else
                  class="white--text"
            >00:00</span>
          </v-col>
        </v-row>-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import VideoCanvasControls from './VideoCanvasControls';
import config from '../../js/config';
import capturesService from '../../js/services/capturesService';

export default {
  name:       'VideoSideBySidePlayer',
  components: {
    VideoCanvasControls,
  },
  props: {
    videos: {
      type:    Array,
      default: () => [],
    },
    showDate: {
      type:    Boolean,
      default: false,
    },
    showTimestamp: {
      type:    Boolean,
      default: false,
    },
  },
  data() {
    return {
      playing:           false,
      duration:          0,
      currentTime:       0,
      durations:         [],
      videoPlayers:      [],
      progress:          0,
      videoRow1:         [],
      videoRow2:         [],
      rows:              [],
      // timer:        null,
      rowXOffset:        [],
      rowYOffset:        [],
      videoHeight:       720,
      videoWidth:        720,
      isLoading:         true,
      minFrameRate:      30,
      canvasRenderCount: 0,
      canvasRenderStart: 0,
      canvasFps:         0,
      videoPlayerStats:  [],
    };
  },
  watch: {
    videos(newVal) {
      // hack, just ignore it for now if its larger then 6
      // our parent will re-shuffle shortly to remove one
      if (newVal.length > 0 && newVal.length <= 6) {
        this.initVideos();
      }
    },

    durations() {
      if (this.videoPlayers.length > 0 && this.durations.length > 0) {
        if (this.durations.length === this.videos.length) {
          const maxDuration = _.maxBy(this.durations, 'duration');

          if (maxDuration) {
            const maxDurationVideo = this.videoPlayers[maxDuration.index];

            this.duration = maxDurationVideo.duration;
            maxDurationVideo.addEventListener('timeupdate', el => {
              this.currentTime = el.target.currentTime;
            }, false);
          }
        }
      }
    },

  },
  created() {
    window.addEventListener('resize', this.windowResized);
  },
  destroyed() {
    // clearInterval(this.timer);
    window.removeEventListener('resize', this.windowResized);
  },
  mounted() {
    this.windowResized();
    // this.timer = setInterval(this.handleFrame, 33);
    this.handleFrame();
  },
  methods: {
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        // console.log('requestAnimationFrame');
        requestAnimationFrame(callback);
      });
    },
    windowResized() {
      this.updateDimensions();

      // this.handleFrame();
    },
    updateDimensions() {
      if (!this.$refs.fullscreenCanvas) {
        return;
      }
      // console.log(`client (WxH) (${this.$refs.fullscreenCanvas.clientWidth}x${this.$refs.fullscreenCanvas.clientHeight}) (WxH) (${this.$refs.fullscreenCanvas.width}x${this.$refs.fullscreenCanvas.height})`);

      if (this.$refs.fullscreenCanvas.clientHeight !== this.$refs.fullscreenCanvas.height) {
        this.$refs.fullscreenCanvas.height = this.$refs.fullscreenCanvas.clientHeight;
      }

      if (this.$refs.fullscreenCanvas.clientWidth !== this.$refs.fullscreenCanvas.width) {
        this.$refs.fullscreenCanvas.width = this.$refs.fullscreenCanvas.clientWidth;
      }
    },
    async initVideos() {
      this.videoPlayers = [];
      this.videoPlayerStats = [];
      this.duration = 0;
      this.currentTime = 0;
      this.durations = [];
      this.minFrameRate = 30;

      if (this.videos.length <= 3) {
        this.videoRow1 = this.videos.slice(0);
        this.videoRow2 = [];
      } else if (this.videos.length === 4) {
        // 4 will be 2 rows by 2 columns
        this.videoRow1 = this.videos.slice(0, 2);
        this.videoRow2 = this.videos.slice(2, 4);
      } else if (this.videos.length === 5) {
        this.videoRow1 = this.videos.slice(0, 3);
        this.videoRow2 = this.videos.slice(3, 5);
        this.videoRow2.push(null);
      } else {
        this.videoRow1 = this.videos.slice(0, 3);
        this.videoRow2 = this.videos.slice(3, 6);
      }

      this.rows = [ this.videoRow1, this.videoRow2 ];

      // Here is a hack to wait for the UI to render and update from the rows change
      // otherwise the video_idx will not be found in certain circumstances
      this.doubleRaf(() => {
        // console.log('doubleRaf callback');
        // After the layout we need to force a resize
        this.windowResized();

        this.videos.forEach((video, index) => {
          let idx = index + 1;

          // console.log(`video index ${index}`);
          // for 4 enabled videos the first row will only have 2 videos
          // so we need to add one to look in the second row
          if (idx >=  3 && this.videos.length === 4) {
            idx++;
          }

          const videoPlayer = this.$refs[`video_${idx}`][0];

          if (videoPlayer) {
            videoPlayer.src = video.video;
            // We will need this for drawing
            videoPlayer.createdDateTime = video.date_created;

            if (this.minFrameRate > capturesService.getFrameRate(video.scan_mode)) {
              this.minFrameRate = capturesService.getFrameRate(video.scan_mode);
            }
            // console.log('player hook up');
            /* videoPlayer.addEventListener('play', () => {
              // console.log('played');
            }, false);*/

            videoPlayer.addEventListener('waiting', () => {
              this.waitingPause(index);
            }, false);

            videoPlayer.addEventListener('canplay', () => {
              // console.log('continuePlay');
              this.continuePlay();
            }, false);

            videoPlayer.addEventListener('loadedmetadata', () => {
            // console.log('loadedmetadata');
              this.durations.push({
                index,
                duration: videoPlayer.duration,
              });
            }, false);

            // console.log(`Pushing player ${idx}`);

            this.videoPlayers.push(videoPlayer);
            this.videoPlayerStats.push({
              parsedFrames:    0,
              decodedFrames:   0,
              presentedFrames: 0,
              paintedFrames:   0,
              frameDelay:      0,
              droppedFrames:   0,
              totalFrames:     0,
            });
          } else {
            // console.error(`player not found video_${idx}`);
          }
        });
      });
    },

    updateVideoDimension() {
      if (!this.$refs.fullscreenCanvas) {
        return;
      }

      const viewWidth = this.$refs.fullscreenCanvas.width;
      const viewHeight = this.$refs.fullscreenCanvas.height;

      // console.log(`(WxH) (${viewWidth}x${viewHeight})`);

      const viewAr = viewWidth / viewHeight;

      switch (this.videos.length) {
        default:
          break;

        case 1: {
          const dim = Math.min(viewWidth, viewHeight);

          this.videoHeight = dim;
          this.videoWidth = dim;

          if (viewAr > 1) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim - viewWidth) / 2);
            this.rowYOffset[0] = 0;
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim - viewHeight) / 2);
          }

          break;
        }

        case 2: {
          const dim = Math.min(Math.round(viewWidth / 2), viewHeight);

          // console.log(`min=${dim}`);
          this.videoHeight = dim;
          this.videoWidth = dim;

          if (viewAr > 2) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
            this.rowYOffset[0] = 0;
            // console.log(`pillarbox=${this.rowYOffset[0]}X${this.rowXOffset[0]} ${viewWidth}x${viewHeight} ${this.videoWidth}x${this.videoHeight}`);
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim - viewHeight) / 2);
            // console.log(`letterbox=${this.rowYOffset[0]}X${this.rowXOffset[0]}`);
          }

          break;
        }

        case 3: {
          const dim = Math.min(Math.round(viewWidth / 3), viewHeight);

          this.videoHeight = dim;
          this.videoWidth = dim;

          if (viewAr > 3) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim * 3 - viewWidth) / 2);
            this.rowYOffset[0] = 0;
            // console.log(`pb ${this.rowXOffset[0]}, ${this.rowYOffset[0]}  ${dim}, ${viewHeight}`);
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim - viewHeight) / 2);
            // console.log(`lb ${this.rowXOffset[0]}, ${this.rowYOffset[0]}  ${dim}, ${viewHeight}`);
          }

          break;
        }

        case 4: {
          const dim = Math.min(Math.round(viewWidth / 2), Math.round(viewHeight / 2));

          this.videoHeight = dim;
          this.videoWidth = dim;

          if (viewAr > 1) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
            this.rowYOffset[0] = 0;
            this.rowXOffset[1] = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
            this.rowYOffset[1] = 0;
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
            this.rowXOffset[1] = 0;
            this.rowYOffset[1] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
          }

          break;
        }

        case 5: {
          const dim = Math.min(Math.round(viewWidth / 3), Math.round(viewHeight / 2));

          this.videoHeight = dim;
          this.videoWidth = dim;

          if (viewAr > 1.5) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim * 3 - viewWidth) / 2);
            this.rowYOffset[0] = 0;
            this.rowXOffset[1] = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
            this.rowYOffset[1] = 0;
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
            this.rowXOffset[1] = Math.round(Math.abs(dim * 2 - viewWidth) / 2);
            this.rowYOffset[1] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
          }

          break;
        }

        case 6: {
          const dim = Math.min(Math.round(viewWidth / 3), Math.round(viewHeight / 2));

          this.videoHeight = dim;
          this.videoWidth = dim;

          // console.log(`${viewAr}`);
          if (viewAr > 1.5) {
            // pillarbox
            this.rowXOffset[0] = Math.round(Math.abs(dim * 3 - viewWidth) / 2);
            this.rowYOffset[0] = 0;
            this.rowXOffset[1] = Math.round(Math.abs(dim * 3 - viewWidth) / 2);
            this.rowYOffset[1] = 0;
            // console.log(`${this.rowXOffset[0]}, ${this.rowYOffset[1]}  ${this.rowXOffset[1]}, ${this.rowYOffset[1]}`);
          } else {
            // letterbox
            this.rowXOffset[0] = 0;
            this.rowYOffset[0] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
            this.rowXOffset[1] = 0;
            this.rowYOffset[1] = Math.round(Math.abs(dim * 2 - viewHeight) / 2);
            // console.log(`${this.rowXOffset[0]}, ${this.rowYOffset[1]}  ${this.rowXOffset[1]}, ${this.rowYOffset[1]}`);
          }

          break;
        }
      }
    },
    getCaptureCreateDate(dateCreated, currentPosition = 0) {
      return this.$moment(dateCreated).add(currentPosition, 'ms').format(config.dateFormat);
    },
    getCaptureCreateTimestamp(dateCreated, currentPosition = 0) {
      return this.$moment(dateCreated).add(currentPosition, 'ms').format(config.timeFormat);
    },
    handleFrame() {
      // console.log('handleFrame');
      if (!this.$refs.fullscreenCanvas) {
        requestAnimationFrame(() => { this.handleFrame(); });

        return;
      }

      const ctx = this.$refs.fullscreenCanvas.getContext('2d');

      if (!ctx) {
        requestAnimationFrame(() => { this.handleFrame(); });

        return;
      }

      if (this.canvasRenderStart === 0) {
        this.canvasRenderStart = performance.now();
      }

      this.updateDimensions();
      this.updateVideoDimension();

      let rows = 1;
      let columns = 1;

      if (this.videos.length >= 4  && this.videos.length <= 6) {
        rows = 2;
      }

      if (this.videos.length === 2 ||  this.videos.length === 4) {
        columns = 2;
      }

      if (this.videos.length === 3 ||  this.videos.length === 5 || this.videos.length === 6) {
        columns = 3;
      }

      ctx.clearRect(0, 0,
        this.$refs.fullscreenCanvas.width, this.$refs.fullscreenCanvas.height);

      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < columns; j++) {
          const idx = i * columns + j;

          const xOffset = this.rowXOffset[i] + (j * this.videoWidth);
          const yOffset = this.rowYOffset[i] + (i * this.videoHeight);

          // console.log(`${idx} ${i} ${j} ${xOffset} ${yOffset}`);
          try {
            if (this.videoPlayers[idx] !== undefined
            && this.videoPlayers[idx] instanceof HTMLVideoElement) {
              ctx.save();
              ctx.drawImage(this.videoPlayers[idx], xOffset, yOffset,
                this.videoWidth, this.videoHeight);

              if (this.showTimestamp || this.showDate) {
                ctx.font = '14px Roboto';

                let overlayText = '';

                if (this.showDate) {
                  overlayText += this.getCaptureCreateDate(
                    this.videoPlayers[idx].createdDateTime, this.currentTime * 1000,
                  );
                  overlayText += ' ';
                }

                if (this.showTimestamp) {
                  overlayText += this.getCaptureCreateTimestamp(
                    this.videoPlayers[idx].createdDateTime, this.currentTime * 1000,
                  );
                }

                const textWidth = ctx.measureText(overlayText).width;

                ctx.beginPath();

                const textYOffset = yOffset +  this.videoHeight - 20;

                ctx.rect(xOffset, textYOffset, textWidth + 6, 20);
                ctx.fillStyle = 'black';
                ctx.fill();

                ctx.fillStyle = 'WhiteSmoke';
                ctx.fillText(overlayText, xOffset + 3, yOffset +  this.videoHeight - 4);
              }

              ctx.restore();
            }

            // console.log(`renderime ${this.renderTime}`);
          } catch (ex) {
            requestAnimationFrame(() => { this.handleFrame(); });

            // console.error(`failed to drawImage ${idx}`);
            // console.error(`failed to drawImage ${ex}`);

            return;
          }
        }
      }
      this.canvasRenderCount += 1;

      if ((this.canvasRenderCount % 5) === 0) {
        this.canvasFps = this.canvasRenderCount
              / ((performance.now() - this.canvasRenderStart) / 1000);
        this.updateStats();
      }

      requestAnimationFrame(() => { this.handleFrame(); });
    },

    updateStats() {
      this.videoPlayers.forEach((player, index) => {
        const qual = player.getVideoPlaybackQuality();

        if (player.mozParsedFrames !== undefined) {
          this.videoPlayerStats[index].parsedFrames =    player.mozParsedFrames;
          this.videoPlayerStats[index].decodedFrames =   player.mozDecodedFrames;
          this.videoPlayerStats[index].presentedFrames = player.mozPresentedFrames;
          this.videoPlayerStats[index].paintedFrames =   player.mozPaintedFrames;
          this.videoPlayerStats[index].frameDelay =      player.mozFrameDelay;
        }
        this.videoPlayerStats[index].droppedFrames =   qual.droppedVideoFrames;
        this.videoPlayerStats[index].totalFrames =     qual.totalVideoFrames;
      });
    },

    getVideoPlayersStats(index) {
      if (this.videoPlayers.length <= 0) {
        return '';
      }

      if (this.videoPlayers[index].mozParsedFrames !== undefined) {
        let txt = `Decode Drop: ${this.videoPlayerStats[index].parsedFrames - this.videoPlayerStats[index].decodedFrames},`;

        txt += ` Present Drop: ${this.videoPlayerStats[index].decodedFrames - this.videoPlayerStats[index].presentedFrames},`;
        txt += ` Paint Drop: ${this.videoPlayerStats[index].presentedFrames - this.videoPlayerStats[index].paintedFrames},`;
        txt += ` Frame Delay: ${this.videoPlayerStats[index].frameDelay},`;
        txt += ` Dropped ${this.videoPlayerStats[index].droppedFrames} frames of ${this.videoPlayerStats[index].totalFrames}`;

        return txt;
      }

      const txt = ` Dropped ${this.videoPlayerStats[index].droppedFrames} frames of ${this.videoPlayerStats[index].totalFrames}`;

      return txt;
    },

    /**
     * Play all videos.
     */
    playVideos() {
      this.canvasRenderStart = 0;
      this.canvasRenderCount = 0;
      this.playing = true;
      this.videoPlayers.forEach(videoPlayer => {
        videoPlayer.play();
      });
      for (let i = 1, j = 0; i <= 6; i++) {
        if (this.$refs[`video_${i}`] !== undefined) {
          const videoPlayer = this.$refs[`video_${i}`][0];

          if (videoPlayer) {
            const fr = capturesService.getFrameRate(this.videos[j].scan_mode);
            const rate = this.minFrameRate / fr;

            // console.log(`Settings ${video.captureId} ${this.minFrameRate}/${video.scan_mode} video_${idx} ${rate}`);
            videoPlayer.playbackRate = rate;
            j++;
          }
        }
      }
    },

    /**
     * Pause all videos.
     */
    pauseVideos() {
      this.playing = false;
      this.videoPlayers.forEach(videoPlayer => {
        videoPlayer.pause();
      });

      // clearInterval(this.timer);
    },

    /**
     * Pause all videos when one in waiting phase.
     *
     * @params {number} - Video index.
     */
    waitingPause(indexWaiting) {
      this.videoPlayers.forEach((videoPlayer, index) => {
        if (indexWaiting !== index) {
          videoPlayer.pause();
        }
      });
    },

    seekVideos(newTime) {
      let isSeeking = false;

      this.videoPlayers.forEach(videoPlayer => {
        if (videoPlayer.seeking) {
          isSeeking = true;
        }
      });
      if (isSeeking) {
        return;
      }

      this.videoPlayers.forEach(videoPlayer => {
        videoPlayer.currentTime = newTime;
      });
    },

    /**
     * Continue play videos after waiting pause.
     *
     * @params {number} - Video index.
     */
    continuePlay() {
      if (this.playing) {
        this.canvasRenderStart = 0;
        this.canvasRenderCount = 0;
        this.videoPlayers.forEach(videoPlayer => {
          videoPlayer.play();
        });
      }
    },

    getIndex(rowIndex, columnIndex) {
      const index = rowIndex * 3 + columnIndex + 1;

      return `video_${index}`;
    },

    showControls() {
      this.show = true;
    },
    hideControls() {
      this.show = false;
    },

  },
};
</script>

<style scoped lang="scss">
@import "../../css/variables";

#video-container {
  height: 70vh;
  background-color: $grey-darken-2;
  #player-controls-container {
    background-color: $grey-lighten-1;
  }
}

.row-height {
  height:100%;
}

.player {
  height: 100%;
  width: 100%;
  position: relative;
}

.hiddenPlayer {
  display: none;
}

</style>

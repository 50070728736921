import Vue from 'vue';
import userService from './userService';

export default {
  /**
   * User list.
   *
   * @var {Array}
   */
  users: [],

  /**
   * Get audit logs list from api.
   *
   * @return {Promise}
   */
  async getLogsList(pageNumber, pageSize) {
    this.users = await userService.getUserList();

    return Vue.axios
      .get(`api/admin/audit?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then(response => this.fillResponse(response.data))
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Get audit logs list from api.
   *
   * @return {Promise}
   */
  async getLogsCount() {
    this.users = await userService.getUserList();

    return Vue.axios
      .get('api/admin/audit/count')
      .then(response => response.data.count)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Fill audit log list with user list data.
   *
   * @param {Array} logs - Audit log list.
   *
   * @return {Array}
   */
  fillResponse(logs) {
    return logs.map(currentLog => {
      const log = currentLog;
      const user = this.users.find(
        userItem => userItem.id === currentLog.user_id,
      );

      if (user) {
        log.userName = user.username;
      }

      return log;
    });
  },
};

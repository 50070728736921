import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import sha512 from 'sha512';
import i18n from '../lang/i18n';
import store from '../store/store';
import { USER_MUTATION } from '../store/mutations';
import { AUTH_USER_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store.
   */
  ...mapMutations([USER_MUTATION]),

  /**
   * Getters from Vuex Storage.
   */
  ...mapGetters([AUTH_USER_GETTER]),

  /**
   * User's permissions.
   *   5 - is the highest it is meant for ovio internal.
   *   4 - is for system technicians.
   *   3 - is for client admins.
   *   1 and 2 - are user levels.
   *   0 - is Everyone.
   */
  permissions: {
    oVio: {
      access_level: 6,
      name:         i18n.t('permissionNames.oVio'),
    },
    technician: {
      access_level: 5,
      name:         i18n.t('permissionNames.technician'),
    },
    administrator: {
      access_level: 4,
      name:         i18n.t('permissionNames.administrator'),
    },
    user2: {
      access_level: 3,
      name:         i18n.t('permissionNames.user2'),
    },
    user1: {
      access_level: 2,
      name:         i18n.t('permissionNames.user1'),
    },
    user0: {
      access_level: 1,
      name:         i18n.t('permissionNames.user0'),
    },
  },

  /**
   * Get user list from api.
   *
   * @return {Promise}
   */
  getUserList() {
    return Vue.axios
      .get('api/users')
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Create user.
   *
   * @params {Array} data - Array with user data.
   *
   * @return {Promise}
   */
  async createUser(data) {
    // For the cloud, we create users without a password.
    if (data.password) {
      const response = await Vue.axios.post('/api/users', {
        ...data,
        password: sha512(data.password).toString('hex'),
      });

      return response.data;
    }

    const response = await Vue.axios.post('/api/users', {
      ...data,
    });

    return response.data;
  },

  /**
   * Update user data.
   *
   * @params {Array} data - Array with user data.
   *
   * @return {Promise}
   */
  async updateUser(data) {
    return Vue.axios
      .put(
        `/api/users/${data.id}`,
        data.password
          ? {
            ...data,
            password: sha512(data.password).toString('hex'),
          }
          : data,
      )
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Returns permission name by level.
   *
   * @param {integer} accessLevel - Permission access level.
   *
   * @return {string}
   */
  getPermissionName(accessLevel) {
    return Object.values(this.permissions).find(
      el => el.access_level === accessLevel,
    ).name;
  },

  /**
   * Get image from api and convert it to base64.
   *
   * @param {string} imagePath - URL to the user's picture
   *
   * @return {string}
   */
  getImage(imagePath) {
    return Vue.axios
      .get(imagePath, {
        headers:      { 'Cache-Control': 'no-cache' },
        responseType: 'arraybuffer',
      })
      .then(response => {
        const image = Buffer.from(response.data, 'binary').toString('base64');

        return `data:${response.headers[
          'content-type'
        ].toLowerCase()};base64,${image}`;
      })
      .catch(error => {
        // console.log(`getImage ${error}`);

        throw error.response;
      });
  },

  /**
   * Update user image.
   *
   * @param {Array} user - Array with user data.
   */
  updateUserImage(user) {
    this.getImage(`api/users/${user.id}/image`)
      .then(res => {
        this[USER_MUTATION]({ ...user, image: res });
      })
      .catch(() => {});
  },

  /**
   * Update user presets.
   *
   * @param {Array} user - Array with user data.
   */
  updatePresetImages(user) {
    this[USER_MUTATION](user);
  },

  /**
   * Update user profile image.
   *
   * @params {Array} params.user - Array with user data.
   * @params {Array} params.imageFile - Array with new user image data.
   *
   * @return {Array}
   */
  async changeUserImage(params) {
    const data = new FormData();

    data.append('UserId', params.user.id);
    data.append('LogoFile', params.imageFile);

    await Vue.axios
      .post('/api/users/image', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(response => {
        this.updateUserImage(params.user);

        return response.data;
      })
      .catch(error => {
        throw error.response;
      });
  },

  /**
   * Update user password.
   *
   * @params {Array} data - Array with old and new user password.
   *
   * @return {Promise}
   */
  async updateUserPassword(data, withAuth) {
    if (withAuth) {
      const response = await Vue.axios.put('/api/users/password', {
        ...data,
        old_password: sha512(data.old_password).toString('hex'),
        new_password: sha512(data.new_password).toString('hex'),
      });

      return response.data;
    }

    const response = await Vue.axios.post('/api/users/password', {
      ...data,
      old_password: sha512(data.old_password).toString('hex'),
      new_password: sha512(data.new_password).toString('hex'),
    });

    return response.data;
  },

  /**
   * Update user password.
   *
   * @params {Array} data - Array with old and new user password.
   *
   * @return {Promise}
   */
  async resetUserPassword(username, email) {
    const response = await Vue.axios.post('/api/users/password/reset', {
      username,
      email,
    });

    return response.data;
  },

  /**
   * Returns authorized user.
   *
   * @return {null|Object}
   */
  getAuthUser() {
    return this[AUTH_USER_GETTER]();
  },

  /**
   * Returns true if user has access_level more than client admin.
   *
   * @return {boolean}
   */
  isAdmin() {
    const user = this[AUTH_USER_GETTER]();

    return (
      user
      && user.access_level
      && [
        this.permissions.oVio.access_level,
        this.permissions.administrator.access_level,
        this.permissions.technician.access_level,
      ].includes(user.access_level)
    );
  },

  /**
   * Returns true if logged user has access to diagnostic tab.
   *
   * @return {boolean}
   */
  isTechnician() {
    const user = this[AUTH_USER_GETTER]();

    return (
      user
      && user.access_level
      && [
        this.permissions.oVio.access_level,
        this.permissions.technician.access_level,
      ].includes(user.access_level)
    );
  },

  /**
   * Returns users permissions available for logged user.
   *
   * @return {Array}
   */
  getAvailablePermissions() {
    return Object.values(this.permissions).filter(
      permission => permission.access_level <= this.getAuthUser().access_level,
    );
  },
};

<template>
  <v-container class="px-12">
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar
            dense
            class="justify-center primary white--text"
          >
            {{ $t('globalSettingsPage.title') }}
          </v-toolbar>
          <div>
            <v-tabs
              slot="extension"
              v-model="tab"
              color="secondary"
              centered
            >
              <v-tab
                v-for="component in components"
                v-show="!component.hide"
                :key="component.id"
              >
                {{ component.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(component, index) in components"
                v-show="!component.hide"
                :key="component.id"
              >
                <v-card :id="component.id"
                        text
                >
                  <component
                    :is="component.component"
                    v-if="tab===index"
                    :id="component.id"
                  />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from '../js/services/userService';
import Users from '../components/globalSettingsTabs/Users';
import AccountDataFields from '../components/globalSettingsTabs/AccountDataFields';
import AccountDocumentFields from '../components/globalSettingsTabs/AccountDocumentFields';
import Machines from '../components/globalSettingsTabs/Machines';
import Locations from '../components/globalSettingsTabs/Locations';
import Tags from '../components/globalSettingsTabs/Tags';
import SystemDiagnostics from '../components/globalSettingsTabs/SystemDiagnostics';
import AuditLog from '../components/globalSettingsTabs/AuditLog';

export default {
  name: 'Settings',
  data() {
    return {
      tab:        null,
      components: [
        {
          id:        'users',
          component: Users,
          name:      this.$t('globalSettingsPage.tabs.users'),
        },
        {
          id:        'accountDataFields',
          component: AccountDataFields,
          name:      this.$t('globalSettingsPage.tabs.accountDataFields'),
        },
        {
          id:        'accountDocumentFields',
          component: AccountDocumentFields,
          name:      this.$t('globalSettingsPage.tabs.accountDocumentFields'),
        },
        {
          id:        'machines',
          component: Machines,
          name:      this.$t('globalSettingsPage.tabs.machines'),
        },
        {
          id:        'locations',
          component: Locations,
          name:      this.$t('globalSettingsPage.tabs.locations'),
        },
        {
          id:        'tags',
          component: Tags,
          name:      this.$t('globalSettingsPage.tabs.tags'),
        },
        {
          id:        'systemDiagnostics',
          component: SystemDiagnostics,
          hide:      !userService.isTechnician(),
          name:      this.$t('globalSettingsPage.tabs.systemDiagnostics'),
        },
        {
          id:        'auditLog',
          component: AuditLog,
          hide:      !userService.isTechnician(),
          name:      this.$t('globalSettingsPage.tabs.auditLog'),
        },
      ],
    };
  },
  computed: {
    /**
     * Returns logged user.
     *
     * @return {null|Object}
     */
    user() {
      return userService.getAuthUser();
    },
  },
  methods: {
    /**
     * Returns permission name.
     *
     * @param {integer} accessLevel - Permission identificator.
     *
     * @return {string}
     */
    getPermissionName(accessLevel) {
      return userService.getPermissionName(accessLevel);
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../css/variables";

.tab-btn {
  height: 25px;
  padding: 0;
  text-transform: capitalize;
}
.tab-btn {
  :global(.orange.darken-3) {
    opacity: 1 !important;
  }
}

.userAvatar {
  object-fit: contain;
  width: 150px;
  height: 150px;
}
</style>

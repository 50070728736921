<template>
  <v-container>
    <v-row v-if="isSlides">
      <v-slide-group
        v-model="currentFrame"
        center-active
        mandatory
        class="py-2"
        min-height="400"
      >
        <template #next />
        <template #prev />
        <v-slide-item
          v-for="(item, index) in items"
          :key="index"
          v-slot="{active}"
        >
          <v-card
            class="ma-2"
            flat
            tile
            :ripple="false"
            @click="selectItem(item)"
          >
            <v-btn
              v-if="isInImageStudio(item)"
              color="secondary"
              class="white--text ml-2 mt-2"
              small
              fab
              absolute
            >
              <v-icon large>
                check
              </v-icon>
            </v-btn>
            <v-btn
              v-show="showFullscreenIcon(item,index)"
              color="transparent"
              class="white--text mt-2"
              absolute
              right
              small
              fab
              @click="launchFullscreen(item)"
              @click.stop
            >
              <v-icon large>
                fullscreen
              </v-icon>
            </v-btn>
            <v-img
              :ref="`image_${index}`"
              :min-width="sliderImageWidth(active)"
              :min-height="sliderImageHeight(active)"
              :max-width="sliderImageWidth(active)"
              :max-height="sliderImageHeight(active)"
              :class="[ $style.item,
                        { [$style.selected]: isSelected(item.image)}]"
              :src="getPreviewUrl(item.image)"
            />
          </v-card>
        </v-slide-item>
      </v-slide-group>
      <v-slider
        v-model="currentFrame"
        thumb-label
        :max="items.length"
      />
    </v-row>
    <v-row v-if="isTiles"
           justify="center"
           class="overflow-y-auto"
           :class="$style.tiles"
    >
      <v-col v-for="(item, index) in items"
             :key="index"
             :cols="tileColumnWidth"
             class="ma-1 pa-1"
      >
        <v-card
          flat
          tile
          :ripple="false"
          @click="selectItem(item)"
        >
          <v-btn
            v-if="isInImageStudio(item)"
            color="secondary"
            class="white--text ma-2"
            small
            fab
            absolute
          >
            <v-icon large>
              check
            </v-icon>
          </v-btn>
          <v-img
            :class="[
              { [$style.selected]: isSelected(item.image)}]"
            :src="getPreviewUrl(item.image)"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isSlides"
           no-gutters
           justify="center"
    >
      <v-btn
        :class="$style.navigation"
        color="grey lighten-2"
        class="mx-2"
        fab
        small
        @click="currentFrame=0"
      >
        <v-icon>first_page</v-icon>
      </v-btn>
      <v-btn
        id="backBtn"
        :class="$style.navigation"
        color="grey lighten-2"
        class="mx-2"
        fab
        small
        @click="currentFrame-=1"
      >
        <v-icon>chevron_left</v-icon>
      </v-btn>
      <v-text-field
        v-model="textInputModel"
        :label="$t('capturePage.details.frameNumber')"
        class="mx-1"
        :class="[$style.fields, $style.inputFrame, $style.centeredInput]"
        color="grey darken-1"
        type="number"
        solo
        dense
      />
      <v-btn
        id="nextBtn"
        :class="$style.navigation"
        color="grey lighten-2"
        class="mx-2"
        fab
        small
        @click="currentFrame+=1"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
      <v-btn
        :class="$style.navigation"
        color="grey lighten-2"
        class="mx-2"
        fab
        small
        @click="currentFrame=items.length-1"
      >
        <v-icon>last_page</v-icon>
      </v-btn>
    </v-row>
    <FullscreenImage
      ref="fullscreenImage"
      :src="fullscreenImage.src"
      :img-width="fullscreenImage.width"
      :img-height="fullscreenImage.height"
      @fullscreen-exit="fullscreenExit"
    />
  </v-container>
</template>
<script>
import config from '../../js/config';
import capturesService from '../../js/services/capturesService';
import imageStudioService from '../../js/services/imageStudioService';
import FullscreenImage from '../FullscreenImage';

export default {
  name:       'CaptureDetailsItems',
  components: {
    FullscreenImage,
  },
  props: {
    items: {
      type:    Array,
      default: () => [],
    },
    view: {
      type:    String,
      default: capturesService.views.slides,
    },
    selectedItems: {
      type:    Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentFrame:    0,
      // currentAngle:       0,
      textInputModel:  '0',
      showFullscreen:  false,
      fullscreenImage: {
        src:    null,
        width:  0,
        height: 0,
      },
    };
  },
  computed: {
    /**
     * Return true if need show frame with details of frame.
     *
     * @return {boolean}
     */
    isSlides() {
      return this.view === capturesService.views.slides;
    },

    isTiles() {
      return this.view === capturesService.views.tiles2
      || this.view === capturesService.views.tiles3;
    },

    tileColumnWidth() {
      if (this.view === capturesService.views.tiles2) {
        return 4;
      }

      return 3;
    },
  },
  watch: {
    textInputModel(newVal) {
      const parsed = Number.parseInt(newVal, 10);

      if (!Number.isNaN(parsed) && this.currentFrame !== parsed) {
        this.currentFrame = parsed > this.items.length - 1 ? this.items.length - 1 : parsed;
      }
    },
    currentFrame(newVal) {
      const parsed = Number.parseInt(this.textInputModel, 10);

      if (!Number.isNaN(parsed) && newVal !== parsed) {
        this.textInputModel = newVal;
      }
    },
  },
  methods: {
    showFullscreenIcon(item, index) {
      // console.log(`${index} ${item.frame}`);
      if (!this.$refs[`image_${index}`]) {
        if (index === 0) {
          // If its not loaded yet, just assume index 0 will be shown
          return true;
        }

        return false;
      }

      return item.frame === this.currentFrame && !this.$refs[`image_${index}`].isLoading;
    },
    sliderImageWidth(active) {
      const scale = 1.2;
      const width = 350;

      if (active) {
        return scale * width;
      }

      return width;
    },

    sliderImageHeight(active) {
      const scale = 1.2;
      const height = 350;

      if (active) {
        return scale * height;
      }

      return height;
    },
    /**
     * Allows input only digits.
     *
     * @param {Object} event - Key press event.
     */
    onlyNumber(event) {
      const evt = (event) || window.event;

      const charCode = (evt.which) ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45) {
        evt.preventDefault();
      }
    },

    /**
     * Select item.
     */
    selectItem(item) {
      if (this.view !== capturesService.views.slides) {
        this.$emit('select', item);
      } else if (this.currentFrame !== item.frame) {
        // this.currentAngle = item.angle;
        this.currentFrame = item.frame;
      } else {
        this.$emit('select', item);
      }
    },

    /**
     * Check if item is in selected.
     *
     * @return {boolean}
     */
    isSelected(item) {
      return this.selectedItems.map(item => item.image).includes(item);
    },

    /**
     * Check if item is already added to image studio.
     *
     * @return {boolean}
     */
    isInImageStudio(item) {
      return imageStudioService.getImageStudioImages().map(item => item.image).includes(item.image);
    },

    /**
     * Return preview image url.
     *
     * @return {string}
     */
    getPreviewUrl(item) {
      return `${config.apiUrl}${item}&width=300&height=300`;
    },

    launchFullscreen(item) {
      // window.open(`${config.apiUrl}${item}`, '_blank');
      // this.$emit('show-overlay', `${config.apiUrl}${item}`);
      this.showFullscreen = true;
      this.fullscreenImage.src = `${config.apiUrl}${item.image}`;
      this.fullscreenImage.height = item.height;
      this.fullscreenImage.width = item.width;
      this.$refs.fullscreenImage.toggleFullscreen();
    },

    fullscreenExit() {
      this.showFullscreen = false;
    },

  },
};
</script>
<style lang="scss" module >
@import "../../css/variables";

.tiles {
  max-height: 600px;
}

.item {
  cursor: pointer;
}

.selected {
  border: 1px solid $orange-text;
  outline: 5px solid $orange-text;
  outline-offset: 0px;
}

.navigation {
  min-width: 40px;
}

.fields {
  width: 100px;
  max-width: 100px!important;
}

.inputFrame input[type='number'] {
    -moz-appearance:textfield;
}
.inputFrame input::-webkit-outer-spin-button,
.inputFrame input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.centeredInput input {
  text-align: center;
}

</style>

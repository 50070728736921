<template>
  <v-container
    class="px-12"
  >
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-card>
          <v-card-title
            class="justify-center primary white--text"
          >
            {{ $t('userPage.title') }}
          </v-card-title>
          <v-card-text>
            <v-row class="mt-4">
              <v-spacer />
              <v-col>
                <v-row class="justify-center">
                  <v-spacer />
                  <v-col>
                    <v-avatar
                      :tile="true"
                      size="150"
                    >
                      <img
                        v-if="user.image"
                        :src="user.image"
                        class="userAvatar"
                      >
                      <img
                        v-else
                        src="@/assets/user-logo.png"
                      >
                    </v-avatar>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col>
                    <ValidationProvider
                      v-slot="{ errors}"
                      ref="provider"
                      name="image"
                      rules="image"
                    >
                      <v-btn
                        text
                        depressed
                        block
                        small
                        color="secondary"
                        @click="pickFile"
                      >
                        {{ $t('userPage.changeImgButton') }}
                      </v-btn>
                      <input
                        ref="image"
                        name="image"
                        type="file"
                        style="display: none"
                        accept="image/*"
                        @change="onFilePicked"
                      >
                      <span class="error--text text-caption">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </v-col>
                  <v-spacer />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-col>
                    <span
                      v-if="successImage"
                      class="success--text"
                    >
                      {{ successImage }}
                    </span>
                    <span
                      v-if="errorImage"
                      class="error--text"
                    >
                      {{ errorImage }}
                    </span>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-col>
              <v-spacer />
              <v-col class="align-center">
                <p class="text-h3 text-sm-left ">
                  {{ user.username }}
                </p>
                <p class="text-h6 text-sm-left ">
                  {{ user.email }}
                </p>
                <p class="text-sm-left ">
                  {{ getPermissionName(user.access_level) }}
                </p>
              </v-col>
              <v-col cols="3" />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col class="mx-6">
                <v-combobox
                  v-model="presetFrames"
                  :items="availablePresetFrames"
                  :label="$t('userPage.presetFrames')"
                  chips
                  multiple
                >
                  <template #selection="{ attrs, item, select, selected }">
                    <v-chip
                      v-bind="attrs"
                      :input-value="selected"
                      class="secondary"
                      color="white"
                      close
                      @click="select"
                      @click:close="removePreset(item)"
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-spacer />
            </v-row>
            <v-row>
              <v-spacer />
              <v-col>
                <v-btn
                  class="grey lighten-3 button mx-1"
                  @click="openChangePassword()"
                >
                  {{ $t('userPage.changePasswordBtn') }}
                </v-btn>
              </v-col>
              <v-spacer />
            </v-row>
            <v-dialog
              v-model="editPasswordDialog"
              persistent
              max-width="400px"
            >
              <v-form>
                <v-card>
                  <ValidationObserver ref="observer"
                                      v-slot="{ invalid, handleSubmit }"
                  >
                    <v-card-title class="white--text primary">
                      <span class="text-h5">
                        {{ $t('userPage.changePassword.title') }}
                      </span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <ValidationProvider
                            v-slot="{ errors, valid }"
                            name="oldPassword"
                            rules="required"
                          >
                            <v-text-field
                              v-model="changePassword.oldPassword"
                              :error-messages="errors"
                              :success="valid"
                              :label="$t('userPage.changePassword.oldPassword')"
                              type="password"
                              color="secondary"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider
                            v-slot="{ errors, valid }"
                            name="newPassword"
                            rules="min:6|max:100|confirmed:password_confirmation"
                          >
                            <v-text-field
                              v-model="changePassword.newPassword"
                              :error-messages="errors"
                              :success="valid"
                              :label="$t('userPage.changePassword.newPassword')"
                              type="password"
                              color="secondary"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <ValidationProvider
                            v-slot="{ errors, valid }"
                            rules="required|max:20|alpha_num"
                            name="password_confirmation"
                            vid="password_confirmation"
                          >
                            <v-text-field
                              v-model="changePassword.verifyPassword"
                              :error-messages="errors"
                              :success="valid"
                              :label="$t('userPage.changePassword.confirmPassword')"
                              type="password"
                              color="secondary"
                            />
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        color="secondary white--text"
                        :disabled="invalid"
                        @click="handleSubmit(updatePassword)"
                      >
                        {{ $t('userPage.changePassword.submit') }}
                      </v-btn>
                      <v-btn @click="editPasswordDialog = false">
                        {{ $t('userPage.changePassword.cancel') }}
                      </v-btn>
                    </v-card-actions>
                  </ValidationObserver>
                </v-card>
              </v-form>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import userService from '../js/services/userService';

export default {
  name:       'UserPage',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      imageFile:             null,
      username:              '',
      password:              '',
      newPassword:           '',
      confirmNewPassword:    '',
      currentEmail:          '',
      newEmail:              '',
      success:               '',
      successImage:          '',
      error:                 '',
      errorImage:            '',
      presetFrames:          [],
      availablePresetFrames: [...Array(361).keys()],
      loading:               false,
      editPasswordDialog:    false,
      changePassword:        {
        oldPassword:    '',
        newPassword:    '',
        verifyPassword: '',
      },
    };
  },
  computed: {
    user() {
      return userService.getAuthUser();
    },
  },
  watch: {
    async presetFrames() {
      if (!this.user) {
        return;
      }

      const newVal = [...this.presetFrames];

      newVal.sort((a, b) => {
        if (parseInt(a, 10) > parseInt(b, 10)) { return 1; }

        if (parseInt(b, 10) > parseInt(a, 10)) { return -1; }

        return 0;
      });

      const presets = newVal.join();

      if (presets !== this.user.preset_image_list) {
        this.user.preset_image_list = presets;

        await userService.updateUser(this.user);

        userService.updatePresetImages(this.user);
      }
    },
  },
  mounted() {
    this.username = this.user.username;
    this.currentEmail = this.user.email;

    const presets = [];

    this.user.preset_image_list.split(',').forEach(preset => {
      presets.push(preset);
    });

    this.presetFrames = presets;
  },
  methods: {
    /**
     * Returns permission name.
     *
     * @param {integer} accessLevel - Permission identificator.
     *
     * @returns {string}
     */
    getPermissionName(accessLevel) {
      return userService.getPermissionName(accessLevel);
    },

    /**
     * Open window for pick image.
     */
    pickFile() {
      this.$refs.image.click();
    },

    openChangePassword() {
      this.changePassword = {
        oldPassword:    '',
        newPassword:    '',
        verifyPassword: '',
      };
      this.editPasswordDialog = true;
    },

    /**
     * Update user image.
     */
    async onFilePicked(e) {
      const { valid } = await this.$refs.provider.validate(e);

      if (!valid) {
        return;
      }

      if (!/\.(jpe?g|png|gif)$/i.test(e.target.files[0].name)) {
        return;
      }

      const files = [...e.target.files];

      if (files[0] !== undefined) {
        const fr = new FileReader();

        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', this.updateImage(files[0]));
      } else {
        this.imageFile = '';
      }
    },

    async updateImage(file) {
      this.loading = true;
      this.errorImage = '';
      try {
        await userService.changeUserImage({
          user:      this.user,
          imageFile: file,
        });
        this.successImage = this.$t('userPage.editUser.successImage');
        this.loading = false;
        userService.getAuthUser();
        setTimeout(() => {
          this.successImage = '';
        }, 3000);
      } catch (error) {
        this.errorImage = error.response.data ? error.response.data : this.$t('userPage.addUser.error');
      }
    },

    removePreset(preset) {
      this.presetFrames = this.presetFrames.filter(p => p !== preset);
    },

    /**
     * Validate and update user password.
     */
    async updatePassword() {
      this.cleanMessages();

      const passwordData = {};

      passwordData.id = this.user.id;
      passwordData.old_password = this.password;
      passwordData.new_password = this.confirmNewPassword;

      try {
        await userService.updateUserPassword({ ...passwordData }, true);
        this.success = this.$t('userPage.editUser.success');
        setTimeout(() => {
          this.password = '';
          this.newPassword = '';
          this.confirmNewPassword = '';
          this.success = '';
          this.$nextTick()
            .then(() => {
              // this.$validator.reset();
              // this.errors.clear();
            });
        }, 3000);
      } catch (error) {
        this.error = error.response.data ? error.response.data : this.$t('userPage.addUser.error');
      }
    },

    /**
     * Clean error and success messages.
     */
    cleanMessages() {
      this.success = '';
      this.error = '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";
@import "../css/components/_container.scss";

.title {
    background-color: $blue-title;
  }

</style>

import Vue from 'vue';
import config from '../config';

export default {

  /**
     * Create the capture export
     *
     * @param {number} id - Capture identifier.
    */
  async createCaptureExport(captureId) {
    const result = await Vue.axios.post(`/api/capture_exports/${captureId}`);

    // console.log(result);
    if (result && result.data.id) {
      return result.data.id;
    }

    return -1;
  },

  async isCaptureExportComplete(captureExportId) {
    const result = await Vue.axios.get(`/api/capture_exports/${captureExportId}/progress`);

    // console.log(result);

    if (result && result.data.progress === 100) {
      return true;
    }

    return false;
  },

  async getExportUrl(id) {
    try {
      if (JSON.parse(process.env.VUE_APP_CLOUD)) {
        const url = `/api/capture_exports/${id}`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      // Handle the local scenario here.
      return `${config.apiUrl}/api/capture_exports/${id}`;
    } catch (e) {
      return '';
    }
  },

  async download(id) {
    const url = await this.getExportUrl(id);

    const response = await Vue.axios.get(url, {
      responseType: 'blob',
    });

    return response.data;
  },

};

<template>
  <v-container
    v-if="projects.length > 0"
    class="px-0 mx-3"
  >
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.imageStudio.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(project, index) in projects"
             :key="index"
             cols="4"
      >
        <v-card
          :ripple="false"
          @click="gotoImageStudioProject(project.id)"
        >
          <v-img
            class="white--text align-end"
            :class="$style.roundedImg"
            :src="getProjectThumbnail(project.id)"
            aspect-ratio="1.5"
          />
          <v-card-text class="text--primary">
            <!--<v-row>
              <v-col class="d-flex justify-begin py-0">
                {{ getCaptureCreateTime(index) }}
              </v-col>
              <v-col cols="1"
                     class="d-flex justify-end py-0"
              >
                <v-icon
                  v-if="capture.audio_file"
                  color="secondary"
                >
                  audiotrack
                </v-icon>
              </v-col>
            </v-row>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '../../js/config';
import imageStudioService from '../../js/services/imageStudioService';
import { IMAGE_STUDIO_REVIEW_PAGE } from '../../js/router/pages';

export default {
  name:  'AccountImageStudio',
  props: {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      projects: [],

    };
  },
  async mounted() {
    await this.loadProjects();
  },
  methods: {
    async loadProjects() {
      this.projects = await imageStudioService.getProjects(this.account.id);
    },
    gotoImageStudioProject(id) {
      this.$router.push({
        name:   IMAGE_STUDIO_REVIEW_PAGE,
        params: {
          imageStudioId: id,
        },
      });
    },
    getProjectThumbnail(id) {
      return `${config.apiUrl}api/image_studio/${id}/page?pageNumber=1&width=300`;
    },
  },
};

</script>

<style lang="scss" module>

.roundedImg {
  border-radius: 4px 4px 0 0;
}

</style>

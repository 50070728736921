import Vue from 'vue';

export default {
  /**
   * Get system diagnostics list from api.
   *
   * @return {Promise}
   */
  getDiagnosticsList(pageNumber, pageSize) {
    return Vue.axios
      .get(`api/admin/diagnostics?pageNumber=${pageNumber}&pageSize=${pageSize}`)
      .then(response => response.data)
      .catch(error => {
        throw error.response;
      });
  },

  getDiagnosticsCount() {
    return Vue.axios
      .get('api/admin/diagnostics/count')
      .then(response => response.data.count)
      .catch(error => {
        throw error.response;
      });
  },
};

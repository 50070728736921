import Vue from 'vue';
import config from '../config';

export default {

  /**
     * Create the avatar
     *
     * @param {number} id - Capture identifier.
    */
  async createAvatar(captureId) {
    await Vue.axios.post(`/api/avatars/${captureId}`);
  },

  async isAvatarComplete(captureId) {
    const result = await Vue.axios.get(`/api/avatars/${captureId}/progress`);

    if (result && result.data.progress === 100) {
      return true;
    }

    return false;
  },
  /**
     * Get the mesh for the avatar
     *
     * @param {number} id - Capture identifier.
     */
  async getModel(id) {
    try {
      if (JSON.parse(process.env.VUE_APP_CLOUD)) {
        const url = `/api/avatars/${id}/model`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      return `${config.apiUrl}api/avatars/${id}/model`;
    } catch (e) {
      return '';
    }
  },

  /**
       * Get texture for the avatar
       *
       * @param {number} id - Capture identifier.
       */
  async getTexture(id) {
    try {
      if (JSON.parse(process.env.VUE_APP_CLOUD)) {
        const url = `/api/avatars/${id}/texture`;
        const response = await Vue.axios.get(url);

        // We get a Url for cloud scenario
        if (response.data.url) {
          return response.data.url;
        }
      }

      return `${config.apiUrl}api/avatars/${id}/texture`;
    } catch (e) {
      return '';
    }
  },

  async download(id) {
    const url = `/api/avatars/${id}/export`;
    const response = await Vue.axios.get(url, {
      responseType: 'blob',
    });

    return response.data;
  },

};

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { configure } from 'vee-validate';
import messages from './en';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale:   'en',
  messages: { en: messages },
});

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);

    return i18n.t(`validations.${values._rule_}`, values);
  },
});

export default i18n;

<template>
  <v-container>
    <v-row
      justify="center"
    >
      <v-col
        sm="4"
        class="login-form"
      >
        <v-card elevation="6">
          <v-row>
            <v-col class="d-flex">
              <v-spacer />
              <v-img
                class="flex"
                max-width="539"
                src="@/assets/logo_LG.png"
              />
              <v-spacer />
            </v-col>
          </v-row>
          <v-card-text
            v-if="!forgotPassword"
            class="pt-4"
          >
            <v-form
              ref="form"
              v-model="validLogin"
              @keyup.native.enter="validLogin && submit($event)"
            >
              <v-label>{{ $t('loginPage.username') }}</v-label>
              <v-text-field
                v-model="username"
                :rules="nameRules"
                color="grey darken-1"
                required
              />
              <v-label>{{ $t('loginPage.password') }}</v-label>
              <v-text-field
                v-model="password"
                :rules="passwordRules"
                type="password"
                color="grey darken-1"
                required
              />
              <v-row
                justify="center"
                class="error-login"
              >
                <label
                  v-if="error"
                  class="error--text"
                >
                  {{ error }}
                </label>
              </v-row>
              <v-row justify="center">
                <v-btn
                  :disabled="!validLogin"
                  class="secondary white--text mr-2"
                  @click="submit"
                >
                  {{ $t('loginPage.login') }}
                </v-btn>
                <v-btn
                  v-if="isCloudEnv"
                  class="grey lighten-3 ml-2"
                  @click="enableForgotPassword"
                >
                  {{ $t('loginPage.forgotPassword') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-text
            v-else
            class="pt-4"
          >
            <v-form
              ref="form"
              v-model="validReset"
              @keyup.native.enter="validReset && submitPasswordReset($event)"
            >
              <v-label>{{ $t('loginPage.email') }}</v-label>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                color="grey darken-1"
                required
              />
              <v-label>{{ $t('loginPage.username') }}</v-label>
              <v-text-field
                v-model="forgotUsername"
                :rules="nameRules"
                color="grey darken-1"
                required
              />
              <v-row
                justify="center"
                class="error-login"
              >
                <label
                  v-if="error"
                  class="error--text"
                >
                  {{ error }}
                </label>
              </v-row>
              <v-row justify="center">
                <v-btn
                  :disabled="!validReset"
                  class="secondary white--text"
                  @click="submitPasswordReset"
                >
                  {{ $t('loginPage.passwordRequest') }}
                </v-btn>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import authService from '../js/services/authService';
import userService from '../js/services/userService';
import { ACCOUNTS_PAGE, LOGIN_PAGE } from '../js/router/pages';

export default {
  name: 'Login',
  data() {
    return {
      error:          '',
      validLogin:     false,
      validReset:     false,
      username:       '',
      forgotUsername: '',
      email:          '',
      password:       '',
      forgotPassword: false,
      isCloudEnv:     JSON.parse(process.env.VUE_APP_CLOUD),
      passwordRules:  [
        v => !!v || this.$t('loginPage.passwordValidate'),
      ],
      nameRules: [
        v => !!v || this.$t('loginPage.usernameValidate'),
      ],
      emailRules: [
        v => !!v || this.$t('loginPage.emailValidate'),
      ],
    };
  },
  async mounted() {
    authService.logout();
    // eslint-disable-next-line no-console
    console.log('Mounted');

    const username = window.sessionStorage.getItem('browserUsername');
    const password = window.sessionStorage.getItem('browserPassword');

    if (username && password) {
      // eslint-disable-next-line no-console
      console.log('Got Session storage');
      this.username = username;
      this.password = password;

      // eslint-disable-next-line no-console
      console.log(`User=${this.username}`);
      await this.submit();

      // eslint-disable-next-line no-console
      console.log('Submitted');
    }
  },
  methods: {
    /**
     * Submit login request.
     */
    async submit() {
      this.error = '';
      try {
        // eslint-disable-next-line no-console
        console.log('Logging in ');

        const route = await authService.login(this.username, this.password);

        // eslint-disable-next-line no-console
        console.log('Logged In');

        if (route && route.name && route.name !== LOGIN_PAGE) {
          this.$router.push(route.path);
          authService.clearLoginRedirectRoute();
        } else {
          this.$router.push({ name: ACCOUNTS_PAGE });
        }
      } catch (res) {
        this.error = this.$t('loginPage.error');
      }
    },

    /**
     * Submit login request.
     */
    async submitPasswordReset() {
      this.error = '';
      try {
        await userService.resetUserPassword(this.forgotUsername, this.email);

        this.error = '';
        this.email = '';
        this.forgotPassword = false;
      } catch (res) {
        this.error = (res && res.data) ? res.data : this.$t('loginPage.errorPassword');
      }
    },

    /**
     * Show the forgot password dialog
     */
    enableForgotPassword() {
      this.forgotPassword = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";
.login-form {
  margin-top: 200px;
}
.v-label {
  font-size: 14px;
}
.v-input {
  margin-bottom: 20px;
}
.error-login {
  position: absolute;
  top: 360px;
  display: flex;
  width: 90%;
}

</style>

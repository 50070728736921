/* eslint-disable */
<script>
import {
  Mesh,
  MeshLambertMaterial,
  MeshStandardMaterial,
  TextureLoader,
} from 'three';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader';
import mixin from './ModelMixin';

export default {
  name:   'ModelPLY',
  mixins: [mixin],
  props:  {
    lights: {
      type: Array,
      default() {
        return [
          {
            type:        'HemisphereLight',
            position:    { x: 0, y: 1, z: 0 },
            skyColor:    0xb3b3b3,
            groundColor: 0x020202,
            intensity:   1.0,
          },
          {
            type:      'AmbientLight',
            color:     0xffffff,
            intensity: 0.8,
          },
        ];
      },
    },
    textureUrl: {
      type:    String,
      default: null,
    },
  },
  data() {
    return {
      loader:        new PLYLoader(),
      textureLoader: new TextureLoader(),
      mesh:          null,
      textureLoaded: false,
    };
  },
  methods: {
    getObject(geometry, texture) {
      geometry.computeVertexNormals();
      // const color = new THREE.Color(0x888888)

      if (texture) {
        this.textureLoaded = false;
        this.mesh = new Mesh(geometry, new MeshStandardMaterial({
          map: texture,
        }));
      } else {
        this.mesh = new Mesh(geometry, new MeshLambertMaterial({
          vertexColors: 2,
          wireframe:    false,
        }));
      }

      return this.mesh;
    },
    setWireframe(isEnabled) {
      this.wireframeEnabled = isEnabled;
      this.mesh.material.wireframe = isEnabled;
    },
    setColor(colorEnabled) {
      this.colorEnabled = colorEnabled;
      if (!this.textureLoaded) {
        this.mesh.material.vertexColors = colorEnabled ? 2 : 0;
      }

      this.mesh.material.needsUpdate = true;
      this.mesh.geometry.colorsNeedUpdate = true;

      let i = 0;

      if (colorEnabled) {
        for (i = 0; i < this.lights.length; i++) {
          if (this.lights[i].type === 'AmbientLight') {
            /* eslint-disable */
            this.lights[i].intensity = 0.8;
          }
        }
      } else {
        for (i = 0; i < this.lights.length; i++) {
          if (this.lights[i].type === 'AmbientLight') {
            /* eslint-disable */
            this.lights[i].intensity = 0.0;
          }
        }
      }
    },
  },
};
</script>

<template>
  <v-container class="grey lighten-2">
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn color="secondary"
               class="mx-2"
               @click="showAddDialog(true)"
        >
          {{ $t('accountDataFields.add') }}
        </v-btn>
        <v-btn class="mx-2"
               :disabled="selectedField.length === 0"
               @click="showAddDialog(false)"
        >
          {{ $t('accountDataFields.edit') }}
        </v-btn>
        <v-btn class="mx-2"
               :disabled="!canDelete()"
               @click="showDeleteDialog()"
        >
          {{ $t('accountDataFields.delete') }}
        </v-btn>
      </v-col>
      <v-col cols="1" />
    </v-row>
    <v-row>
      <v-col cols="1" />
      <v-col cols="10">
        <v-data-table v-model="selectedField"
                      :headers="headers"
                      :items="accountFields"
                      :loading="loading"
                      item-key="id"
                      show-select
                      single-select
        >
          <template #[`item.type`]="{item}">
            <span>
              {{ getTypeName(item.type) }}
            </span>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="1" />
    </v-row>

    <v-dialog v-model="dialog"
              persistent
              max-width="700px"
    >
      <ValidationObserver ref="obs"
                          v-slot="{ invalid, handleSubmit }"
      >
        <v-card>
          <v-card-title class="text-h6 white--text primary">
            <span class="text-h5">
              {{
                isNewField ?
                  $t('accountDataFields.addDialog.addTitle'):
                  $t('accountDataFields.addDialog.editTitle')

              }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <ValidationProvider v-slot="{ errors, valid }"
                                      name="key"
                                      rules="required|max:45"
                  >
                    <v-text-field v-model="newField.key"
                                  :error-messages="errors"
                                  :success="valid"
                                  :label="$t('accountDataFields.addDialog.key')"
                                  color="secondary"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="newField.description"
                                :error-messages="errors"
                                :success="valid"
                                :label="$t('accountDataFields.addDialog.description')"
                                color="secondary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <ValidationProvider v-slot="{ errors }"
                                      name="type"
                                      rules="required"
                  >
                    <v-select v-model="newField.type"
                              :items="accountFieldTypes"
                              :error-messages="errors"
                              menu-props="auto"
                              :label="$t('accountDataFields.addDialog.type')"
                              hide-details
                              single-line
                              :readonly="!isNewField"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field v-model="newField.table_position"
                                v-text-field
                                :error-messages="errors"
                                :success="valid"
                                :disabled="newField.required"
                                :label="$t('accountDataFields.addDialog.tablePosition')"
                                color="secondary"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="newField.visible"
                              :label="$t('accountDataFields.addDialog.visible')"
                              class="my-2"
                              color="secondary"
                  />
                </v-col>
                <v-col>
                  <v-checkbox v-model="newField.required"
                              :label="$t('accountDataFields.addDialog.required')"
                              class="my-2"
                              color="secondary"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="secondary"
                   :disabled="invalid"
                   @click="handleSubmit(addField)"
            >
              {{ newField.id ? $t('accountDataFields.addDialog.save')
                : $t('accountDataFields.addDialog.add') }}
            </v-btn>
            <v-btn color="gray darken-3"
                   @click="dialog = false"
            >
              {{ $t('accountDataFields.addDialog.cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>
    <v-dialog v-model="deleteDialog"
              persistent
              width="290"
    >
      <v-card>
        <v-card-title class="text-h6 white--text primary">
          {{ $t('accountDataFields.deleteDialog.title') }}
        </v-card-title>
        <v-card-text>
          {{ $t('accountDataFields.deleteDialog.message') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary white--text v-btn"
                 @click="deleteField"
          >
            {{ $t('accountDataFields.deleteDialog.submit') }}
          </v-btn>
          <v-btn color="gray darken-3"
                 @click="deleteDialog = false"
          >
            {{ $t('accountDataFields.deleteDialog.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="addSuccessSnackbar"
                color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('accountDataFields.addDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="addErrorSnackbar"
                color="error"
    >
      <div class="font-weight-bold">
        {{ $t('accountDataFields.addDialog.error') }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="deleteSuccessSnackbar"
                color="secondary"
    >
      <div class="font-weight-bold">
        {{ $t('accountDataFields.deleteDialog.success') }}
      </div>
    </v-snackbar>
    <v-snackbar v-model="deleteErrorSnackbar"
                color="error"
    >
      <div class="font-weight-bold">
        {{ $t('accountDataFields.deleteDialog.error') }}
      </div>
    </v-snackbar>
  </v-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import accountFieldsService from '../../js/services/accountFieldsService';

export default {
  name:       'AccountFields',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      loading:               'secondary',
      accountFields:         [],
      dialog:                false,
      deleteDialog:          false,
      isNewField:            true,
      selectedField:         [],
      addSuccessSnackbar:    false,
      addErrorSnackbar:      false,
      deleteSuccessSnackbar: false,
      deleteErrorSnackbar:   false,
      newField:              {
        key:            '',
        description:    '',
        sort_order:     0,
        visible:        false,
        required:       false,
        permanent:      false,
        type:           0,
        width:          3,
        table_position: -1,
      },
      accountFieldTypes: [
        { text: this.$t('accountDataFields.fieldTypes.text'), value: 1 },
        { text: this.$t('accountDataFields.fieldTypes.gender'), value: 2 },
        { text: this.$t('accountDataFields.fieldTypes.date'), value: 3 },
        { text: this.$t('accountDataFields.fieldTypes.height'), value: 4 },
        { text: this.$t('accountDataFields.fieldTypes.phone'), value: 5 },
        { text: this.$t('accountDataFields.fieldTypes.email'), value: 6 },
      ],
      headers: [
        {
          text:  this.$t('accountDataFields.headers.id'),
          align: 'center',
          value: 'id',
        },
        {
          text:  this.$t('accountDataFields.headers.description'),
          align: 'center',
          value: 'description',
        },
        {
          text:  this.$t('accountDataFields.headers.permanent'),
          align: 'center',
          value: 'permanent',
        },
        {
          text:  this.$t('accountDataFields.headers.type'),
          align: 'center',
          value: 'type',
        },
        {
          text:  this.$t('accountDataFields.headers.visible'),
          align: 'center',
          value: 'visible',
        },
        {
          text:  this.$t('accountDataFields.headers.width'),
          align: 'center',
          value: 'width',
        },
        {
          text:  this.$t('accountDataFields.headers.table_position'),
          align: 'center',
          value: 'table_position',
        },
      ],
    };
  },
  created() {
    this.loading = 'secondary';
    this.updateAccountDataFields();
    accountFieldsService.loadAccountFields();
    this.loading = false;
  },
  methods: {

    getTypeName(type) {
      return this.accountFieldTypes.find(f => f.value === type).text;
    },

    /**
     * Load account fields.
     */
    async updateAccountDataFields() {
      this.selectedField = [];
      this.accountFields = await accountFieldsService.getAllAccountFields();
    },

    /**
     * Delete account field data.
     */
    async deleteField() {
      try {
        const accountDataFieldID = this.selectedField[0].id;

        await accountFieldsService.deleteAccountField(accountDataFieldID);
        this.updateAccountDataFields();
        this.deleteDialog = false;
        this.deleteSuccessSnackbar = true;
      } catch (error) {
        this.deleteErrorSnackbar = true;
      }
    },

    /**
     * Add or update new account field data.
     */
    async addField() {
      try {
        if (this.newField.id) {
          await accountFieldsService.updateAccountField({ ...this.newField });
        } else {
          await accountFieldsService.addAccountField({ ...this.newField });
        }

        this.updateAccountDataFields();
        this.dialog = false;
        this.addSuccessSnackbar = true;
      } catch (error) {
        this.dialog = false;
        this.addErrorSnackbar = true;
      }
    },

    /**
     * Show add/edit account data fields popup.
     *
     * @param {boolean} isNew - Is account data field.
     * @param {integer} index - Index of account data field.
     */
    showAddDialog(isNew) {
      if (isNew) {
        this.isNewField = true;
        this.newField = {};
      } else {
        this.isNewField = false;
        this.newField = { ...this.selectedField[0] };
      }
      this.dialog = true;
    },

    /**
     * Show popup for delete account data field.
     */
    showDeleteDialog() {
      this.deleteDialog = true;
    },

    canDelete() {
      if (this.selectedField.length === 0) {
        return false;
      }

      return !this.selectedField[0].permanent;
    },

  },
};
</script>

<style lang="scss" scoped>
  @import '../../css/components/_table.scss';

  .title {
    background-color: $blue-title;
  }

  .iconVisible {
    margin-top: 12px;
    display: block;
    float: left;
  }
</style>

<template>
  <v-container
    v-if="videos.length > 0"
    class="px-0 mx-3"
  >
    <v-row>
      <v-col>
        <h1
          class="justify-center text-h5"
        >
          {{ $t('accountPage.videoStudio.title') }}
        </h1>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col v-for="(video, index) in videos"
             :key="index"
             cols="4"
      >
        <v-card
          :ripple="false"
          @click="gotoVideoStudioProject(video.id)"
        >
          <v-img v-if="video.thumbnail"
                 class="white--text align-end"
                 :class="$style.roundedImg"
                 :src="getVideoThumbnail(video.id)"
                 aspect-ratio="1.5"
          />
          <v-img v-else
                 class="d-flex justify-center align-center"
                 width="300"
                 aspect-ratio="1.5"
                 :src="getVideoThumbnail(video.id)"
          >
            <v-icon
              class="d-flex justify-center align-center"
              x-large
            >
              ondemand_video
            </v-icon>
          </v-img>
          <v-card-text class="text--primary">
            <!--<v-row>
              <v-col class="d-flex justify-begin py-0">
                {{ getCaptureCreateTime(index) }}
              </v-col>
              <v-col cols="1"
                     class="d-flex justify-end py-0"
              >
                <v-icon
                  v-if="capture.audio_file"
                  color="secondary"
                >
                  audiotrack
                </v-icon>
              </v-col>
            </v-row>-->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '../../js/config';
import videoStudioService from '../../js/services/videoStudioService';
import { VIDEO_STUDIO_REVIEW_PAGE } from '../../js/router/pages';

export default {
  name:  'AccountVideoStudio',
  props: {
    account: {
      type:    Object,
      default: () => {},
    },
  },
  data() {
    return {
      videos: [],

    };
  },
  async mounted() {
    await this.loadProjects();
  },
  methods: {
    async loadProjects() {
      this.videos = await videoStudioService.getVideos(this.account.id);
    },
    gotoVideoStudioProject(id) {
      this.$router.push({
        name:   VIDEO_STUDIO_REVIEW_PAGE,
        params: {
          videoStudioId: id,
        },
      });
    },
    getVideoThumbnail(id) {
      return `${config.apiUrl}api/video_studio/${id}/thumbnail?width=300`;
    },
  },
};
</script>

<style lang="scss" module>

.roundedImg {
  border-radius: 4px 4px 0 0;
}

</style>

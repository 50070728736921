import {
  // eslint-disable-next-line camelcase
  required, email, max, min, image, confirmed, alpha_num,
} from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', required);
extend('email', email);
extend('max', max);
extend('min', min);
extend('image', image);
extend('confirmed', confirmed);
extend('alpha_num', alpha_num);

<template>
  <v-row class="justify-center">
    <v-col v-for="(page, index) in pageInfo.images"
           :key="index"
           cols="6"
           class="py-0"
    >
      <v-row>
        <v-spacer />
        <Editor
          :ref="`editor${index}`"
          :canvas-width="canvasWidth+index"
          :canvas-height="canvasHeight+index"
          :width="canvasWidth+index"
          :height="canvasHeight+index"
          :editor-number="index"
          @image-loaded="imageLoaded()"
        />
        <v-spacer />
      </v-row>
      <v-row>
        <v-col cols="1" />
        <v-col class="d-flex align-center"
               cols="6"
        >
          <span>
            {{ 'Capture Id: ' + page.captureId + ' Frame:' + page.frame }}
          </span>
        </v-col>
        <v-spacer />
        <v-col cols="3" />
        <v-col cols="1">
          <v-menu
            close-on-click
            offset-y
            offset-x
          >
            <template #activator="{on}">
              <v-btn
                color="secondary"
                icon
                tile
                :ripple="false"
                v-on="on"
              >
                <v-icon>
                  more_horiz
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="undo(index)"
              >
                <v-list-item-title>
                  {{ $t('imageStudio.undo') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="clear(index)"
              >
                <v-list-item-title>
                  {{ $t('imageStudio.clear') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="removeFromPage(index)"
              >
                <v-list-item-title>
                  {{ $t('imageStudio.removePage') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="1" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Editor from './Editor';
// import config from '../../js/config';
import imagesService from '../../js/services/imagesService';
import imageStudioService from '../../js/services/imageStudioService';

export default {
  name:       'ImageEditorPage',
  components: {
    Editor,
  },
  props: {
    pageInfo: {
      type: Object,
      default() {
        return {
          images:   [],
          commands: [],
        };
      },
    },
    // zero based index
    pageIndex: {
      type:    Number,
      default: -1,
    },
  },
  data() {
    return {
      // canvasWidth:      425,
      // canvasHeight:     425,
      imageLoadedCount: 0,
    };
  },
  computed: {
    canvasWidth() {
      return this.computeCanvasDimensions();
    },
    canvasHeight() {
      return this.computeCanvasDimensions();
    },
  },
  watch: {
    pageInfo: {
      deep: true,
      handler() {
        this.loadContent();
      },
    },
  },
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    this.saveCommands();
    this.$context.history.clear();
  },
  methods: {
    async loadFrame(index, url) {
      // console.log(`loadFrame ${index} ${JSON.stringify(url)}`);
      if (url && url !== '') {
        const img = await imagesService.loadImage(url);
        const blob = await fetch(img).then(res => res.blob());

        const e = { target: { files: [blob] } };
        const editor = this.$refs[`editor${index}`][0];

        editor.setImage(e);
      }
    },
    computeCanvasDimensions() {
      // console.log(`Size ${this.$vuetify.breakpoint.name} HxW ${this.$vuetify.breakpoint.width}x${this.$vuetify.breakpoint.height}`);
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 225;

        case 'sm':
          return 325;

        case 'md':
          return 400;

        case 'lg':
          return 425;

        default:
          return 1200;

        case 'xl':
          return 600;
      }
    },
    loadContent() {
      this.imageLoadedCount = 0;
      this.pageInfo.images.forEach((page, index) => {
        this.loadFrame(index, page.image);
      });
    },
    saveCommands() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.endTextDraw();
      }

      const cmds = this.getCommands();

      this.$emit('commands-updated', this.pageIndex, cmds);
    },
    imageLoaded() {
      // console.log(`imgLoaded ${this.imageLoadedCount} ${this.pageInfo.images.length}`);
      this.imageLoadedCount++;

      if (this.imageLoadedCount === this.pageInfo.images.length) {
        // console.log(`Commands2 ${this.pageInfo.commands}`);
        this.pageInfo.commands.forEach(cmd => {
          // console.log(`iamgeLoaded cmd.cid=${cmd.commandName} imageLoadedCount=${this.imageLoadedCount}`);
          if (this.$refs[`editor${cmd.cid}`]) {
            const editor = this.$refs[`editor${cmd.cid}`][0];

            editor.addCommand(cmd);
          }
        });

        this.$emit('page-loaded');
      }
    },
    enableLine(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableFreeDraw(params);
      }
    },
    enableRectangle(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableRect(params);
      }
    },
    enableEllipse(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableEllipse(params);
      }
    },
    enableText(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableText(params);
      }
    },
    enableArrow(params) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableArrow(params);
      }
    },
    enableCrop() {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.enableCrop();
      }
    },
    undo(index) {
      if (index) {
        let found = 0;
        const cmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (index === cmd.cid && !found) {
            found = 1;
            cmd.undo();
          } else {
            cmds.push(cmd);
          }
        }
        cmds.reverse();
        cmds.forEach(c => {
          this.$context.history.push(c);
        });
      } else if (this.$context.history.length) {
        this.$context.history.pop().undo();
      }

      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        editor.refresh();
      }
    },
    clear(index) {
      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (cmd.cid === index) {
          cmd.undo();
        } else {
          cmds.push(cmd);
        }
      }
      cmds.reverse();
      cmds.forEach(c => {
        this.$context.history.push(c);
      });

      const editor = this.$refs[`editor${index}`][0];

      editor.refresh();
    },
    removeCommands(imageIndex) {
      // console.log(`Removing commands from page ${imageIndex}`);

      const cmds = [];

      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        if (imageIndex !== cmd.cid) {
          cmds.push(cmd);
        }

        if (cmd.cid > imageIndex) {
          // console.log(`Removing command decrementing ${cmd.cid}`);
          cmd.cid--;
        }
      }

      cmds.reverse();
      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }
    },
    getCommands() {
      const exportInfo = [];
      const cmds = [];

      // console.log(`getCommands ${this.$context.history.length}`);
      while (this.$context.history.length > 0) {
        const cmd = this.$context.history.pop();

        exportInfo.push(cmd.export());
        cmds.push(cmd);
      }
      cmds.reverse();

      while (cmds.length > 0) {
        this.$context.history.push(cmds.pop());
      }

      return exportInfo.reverse();
    },
    async save(projectId, pageNumber) {
      for (let i = 0; i < this.pageInfo.images.length; i++) {
        const editor = this.$refs[`editor${i}`][0];

        const bgParams = {
          scaleX: editor.canvas.width / (editor.canvas.backgroundImage.scaleX
          * editor.canvas.backgroundImage.width),
          scaleY: editor.canvas.height / (editor.canvas.backgroundImage.scaleY
          * editor.canvas.backgroundImage.height),
          translateX: editor.canvas.backgroundImage.left
          / editor.canvas.backgroundImage.scaleX,
          translateY: editor.canvas.backgroundImage.top
          / editor.canvas.backgroundImage.scaleY,
        };

        const imgData = editor.saveImage();
        const cmds = [];
        const currentCmds = [];

        while (this.$context.history.length > 0) {
          const cmd = this.$context.history.pop();

          if (cmd.cid === i) {
            cmds.push(cmd.export());
          }

          currentCmds.push(cmd);
        }
        cmds.reverse();
        currentCmds.reverse();

        while (currentCmds.length > 0) {
          this.$context.history.push(currentCmds.pop());
        }

        const data = {
          captureId:               this.pageInfo.images[i].captureId,
          cameraCaptureSettingsId: this.pageInfo.images[i].cameraCaptureSettingsId,
          angle:                   this.pageInfo.images[i].angle,
          frame:                   this.pageInfo.images[i].frame,
          commands:                JSON.stringify(cmds),
          cropInfo:                bgParams,
          pageNumber,
        };

        data.data = imgData;

        // TODO We disable this warning because it must be done in a loop because the
        // API only handles it one at a time. We can do better and allow the API to do one
        // upload with all the images. Future work.
        /* eslint-disable no-await-in-loop */
        await imageStudioService.uploadProjectImage(projectId, data);
      }
    },
    removeFromPage(index) {
      this.$emit('remove-page', index);
    },
  },

};
</script>
